body {
  color: #515151;
  background: #eee;
  width: 100%;
  padding: 0;
  margin: 0;
  height: 100vh;
  font-family: Proxima Nova, sans-serif;
}

.bandera--inicio {
  background-image: url('./assets/bandera-inicio.svg');
  width: 48px;
  height: 48px;
  background-size: contain;
}

.bandera--final {
  background-image: url('./assets/bandera-final.svg');
  width: 48px;
  height: 48px;
  background-size: contain;
}

.bandera--entregado {
  background-image: url('./assets/bandera-entregado.svg');
  width: 48px;
  height: 48px;
  background-size: contain;
}

.bandera--pendiente {
  background-image: url('./assets/bandera-pendiente.svg');
  width: 48px;
  height: 48px;
  background-size: contain;
}

.camion--nk {
  background-image: url('./assets/camion_nk.png');
  background-size: cover;
  width: 30px;
  height: 60px;
  cursor: pointer;
}

.camion--nd {
  background-image: url('./assets/camion_nidera.png');
  background-size: cover;
  width: 30px;
  height: 60px;
  cursor: pointer;
}

.camion--sps {
  background-image: url('./assets/camion_sps.png');
  background-size: cover;
  width: 30px;
  height: 60px;
  cursor: pointer;
}

.camion--offline {
  background-image: url('./assets/camion_offline.png');
  background-size: cover;
  width: 30px;
  height: 60px;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.mapboxgl-popup-camion .mapboxgl-popup-tip {
  border-top-color: #333;
}

.mapboxgl-popup-camion .mapboxgl-popup-content {
  background: #333;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.mapa {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ag-center-aligned-header .ag-header-cell-label {
  justify-content: center;
}

.toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.toggle-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.toggle-check,
.toggle-uncheck {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  opacity: 0;
  transition: opacity 0.25s ease;
}
.toggle-check {
  left: 8px;
}
.toggle-uncheck {
  opacity: 1;
  right: 10px;
}

.toggle-uncheck span,
.toggle-check span {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: center;
  position: relative;
  width: 10px;
}

.toggle-container {
  width: 45px;
  height: 24px;
  padding: 0;
  border-radius: 25px;
  background-color: #c4c4c4;
  transition: all 0.2s ease;
}

.toggle-circle {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #f8f8f8;
  box-sizing: border-box;
  transition: all 0.25s ease;
}
.toggle--checked .toggle-check {
  opacity: 1;
}
.toggle--checked .toggle-uncheck {
  opacity: 0;
}
.toggle--checked .toggle-circle {
  left: 23px;
}
.toggle--checked .toggle-container {
  background-color: #216aff;
}

.marker-destino {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.apexcharts-svg {
  overflow: visible;
}

.apexcharts-menu-item.exportCSV {
  display: none;
}

.no-glow:focus {
  box-shadow: none;
}
